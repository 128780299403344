<template>
  <div class="about" data-view>
    <hero-with-image
      title="“be eco-conscious”"
      :neera-badge="true"
      pre-description="the mellow ambiance, combined with mindful adherence to green practices, even in minor details, leaves visitors with simple ideas they can develop in and around their own homes or workplaces to help the environment."
      description="<p>upon arrival, guests will be greeted with a gradated palette of earth tone vibes that exude a warm, welcoming feeling and a sense of seamless unity.</p>

<p>recycling bins are placed throughout the premises and all packages are eco-friendly or made of recyclable materials. in the kitchen, locally sourced ingredients are used to ensure freshness, save on transportation energy and promote the community’s produce. takeaway packaging, and utensils are all made from biodegradable materials, so there’s no unnecessary waste.</p>

<p>wastewater from the hotel is treated properly before disposal to minimize the environmental impact.</p>"
      exploreLink=""
      :imageUrl="require('@/assets/about/eco-concious/ABOUT_03_ecocons_03.png')"
      class="mb-18 lg:mb-20 lg:mt-8"
    />
    <div class="my-18 lg:my-20" data-aos="fade-up">
      <quote-break
        title='
      <p class="text-np-3xl saoltext">
        eco–conscious <span class="whitespace-nowrap">hotel service</span>
      </p>
      '
        description="<span class='saoltext whitespace-nowrap'>‘neera eco-kit'</span> is
      provided to reduce single-use plastic: shopping bag, lunch box, utensils,
      tumbler, and straw. we provide reusable packaging for our in-room
      amenities; organic bulk shampoo, soap and lotion dispenser, tooth tab
      (chewable toothpaste), and local snack minibar. moreover, we also provide
      an in-room food waste bin for guests to manage their food waste and 100%
      electric transportation partnered with EV Taxi creating low emission
      transport to and from the airport. it is important for us to understand
      deeply the <span class='whitespace-nowrap'>eco-concept</span> through activities within the hotel to reduce
      carbon footprints on the planet."
      />
    </div>
    <div class="my-18 lg:my-20 mx-4 lg:mx-8" data-aos="fade-up">
      <photo-horizon :photos="images" />
    </div>
    <neera-grid>
      <neera-grid-row data-aos="fade-up">
        <div>
          <img
            class="img-para w-full rounded-tr-np-lg"
            src="@/assets/about/eco-concious/ECOCONS_03_ecomaterial-01.png"
          />
        </div>
        <div class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2">
          <p class="saoltext text-np-green font-light text-np-2xl">
            eco–material design
          </p>
          <div>
            <p class="robototext neera-text-green content-para">
              some remaining hotel construction materials are upcycled, creating
              unique decorations and utensils. durable materials are mainly
              focused on long-term use. additionally, 100% eco-paper and
              recycled paper are used for all marketing materials and also at
              our front and back offices.
            </p>
          </div>
        </div>
      </neera-grid-row>

      <neera-grid-row data-aos="fade-up">
        <div>
          <img
            class="img-para w-full"
            src="@/assets/about/eco-concious/01_neeragreenspace.png"
          />
        </div>
        <div class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2">
          <p class="saoltext text-np-green font-light text-np-2xl">
            eco–conscious air quality
          </p>
          <div>
            <p class="robototext neera-text-green content-para">
              being the lungs of the hotel,
              <span class="whitespace-nowrap"
                >our <span class="saoltext">'neera green space'</span></span
              >
              emphasizes green and shady zones where plants are grown around the
              common area and garden. our architectural design enhances the
              fresh air ventilation which brings the fresh breeze into our lobby
              and common areas. essential oils and natural identical substance
              is used instead of the usual air fresheners.
              <span class="saoltext whitespace-nowrap">'neera'</span> is a
              non-smoking hotel both in-room and common areas: we do provide
              designated smoking-only areas.
            </p>
          </div>
        </div>
      </neera-grid-row>
    </neera-grid>

    <div
      class="flex flex-col facilities px-4 lg:px-32 py-16 my-18 lg:my-20 space-y-8 lg:space-y-10"
      data-aos="fade-up"
    >
      <div
        class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-y-0 pt-8 lg:pt-0"
      >
        <div class="text-left lg:px-8 py-2 max-w-screen-lg/2 flex items-center">
          <div class="space-y-4">
            <p class="saoltext text-np-3xl text-np-glimpse">eco–water system</p>
            <div>
              <div class="neera-text-green flex flex-col content-para">
                <div class="flex flex-row">
                  <div class="flex flex items-start">
                    <p class="inline-flex">
                      <span class="justify-self-start py-1">
                        <svg
                          width="22"
                          height="16"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 0.472656L6.53125 0.691406C6.375 0.847656 6.375 1.06641 6.53125 1.22266L12.0938 6.78516H0.375C0.15625 6.78516 0 6.97266 0 7.16016V7.47266C0 7.69141 0.15625 7.84766 0.375 7.84766H12.0938L6.53125 13.4414C6.375 13.5977 6.375 13.8164 6.53125 13.9727L6.75 14.1914C6.90625 14.3477 7.125 14.3477 7.28125 14.1914L13.875 7.59766C14.0312 7.44141 14.0312 7.22266 13.875 7.06641L7.28125 0.472656C7.125 0.316406 6.90625 0.316406 6.75 0.472656Z"
                            fill="#5C623F"
                          />
                        </svg>
                      </span>
                      <span>using low-flow shower heads and toilets</span>
                    </p>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex items-start">
                    <p class="inline-flex">
                      <span class="justify-self-start py-1">
                        <svg
                          width="22"
                          height="16"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 0.472656L6.53125 0.691406C6.375 0.847656 6.375 1.06641 6.53125 1.22266L12.0938 6.78516H0.375C0.15625 6.78516 0 6.97266 0 7.16016V7.47266C0 7.69141 0.15625 7.84766 0.375 7.84766H12.0938L6.53125 13.4414C6.375 13.5977 6.375 13.8164 6.53125 13.9727L6.75 14.1914C6.90625 14.3477 7.125 14.3477 7.28125 14.1914L13.875 7.59766C14.0312 7.44141 14.0312 7.22266 13.875 7.06641L7.28125 0.472656C7.125 0.316406 6.90625 0.316406 6.75 0.472656Z"
                            fill="#5C623F"
                          />
                        </svg>
                      </span>
                      <span>
                        using bio-based and non-toxic cleaning products to resolve industrial waste
                      </span>
                    </p>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="flex flex items-start">
                    <p class="inline-flex">
                      <span class="justify-self-start py-1">
                        <svg
                          width="22"
                          height="16"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 0.472656L6.53125 0.691406C6.375 0.847656 6.375 1.06641 6.53125 1.22266L12.0938 6.78516H0.375C0.15625 6.78516 0 6.97266 0 7.16016V7.47266C0 7.69141 0.15625 7.84766 0.375 7.84766H12.0938L6.53125 13.4414C6.375 13.5977 6.375 13.8164 6.53125 13.9727L6.75 14.1914C6.90625 14.3477 7.125 14.3477 7.28125 14.1914L13.875 7.59766C14.0312 7.44141 14.0312 7.22266 13.875 7.06641L7.28125 0.472656C7.125 0.316406 6.90625 0.316406 6.75 0.472656Z"
                            fill="#5C623F"
                          />
                        </svg>
                      </span>
                      <span>better water treatment</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <img
            class="hidden md:flex object-cover w-full"
            src="@/assets/about/eco-concious/DESKTOPECOCONS_05_ecowater-cycle_DESKTOP.svg"
          />
          <img
            class="flex md:hidden w-auto h-auto"
            src="@/assets/about/eco-concious/mobile/MOBILEECOCONS_05_ecowater-cycle.png"
          />
        </div>
      </div>
      <div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-x-4">
          <div class="flex flex-col w-full h-np-196 items-center text-np-green">
            <div class="h-np-140 w-np-140">
              <img
                class="object-cover w-full"
                src="@/assets/about/eco-concious/lightbulb.svg"
              />
            </div>
            <div class="font-np-base font-light robototext">
              <span>LED light bulb</span>
            </div>
          </div>
          <div class="flex flex-col w-full h-np-196 items-center text-np-green">
            <div class="h-np-140 w-np-140">
              <img
                class="object-cover w-full"
                src="@/assets/about/eco-concious/aircon.svg"
              />
            </div>
            <div class="font-np-base font-light robototext">
              <span
                >energy star-rated<br />
                air condition</span
              >
            </div>
          </div>
          <div class="flex flex-col w-full h-np-196 items-center text-np-green">
            <div class="h-np-140 w-np-140">
              <img
                class="object-cover w-full"
                src="@/assets/about/eco-concious/hotwater.svg"
              />
            </div>
            <div class="font-np-base font-light robototext">
              <span
                >energy-efficient<br />
                hot water making machine</span
              >
            </div>
          </div>
          <div class="flex flex-col w-full h-np-196 items-center text-np-green">
            <div class="h-np-140 w-np-140">
              <img
                class="object-cover w-full"
                src="@/assets/about/eco-concious/airflow.svg"
              />
            </div>
            <div class="font-np-base font-light robototext">
              <span
                >air flow<br />
                building design</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <neera-grid class="my-18 lg:my-20">
      <neera-grid-row data-aos="fade-up">
        <div>
          <img
            class="img-para w-full rounded-tr-np-lg"
            src="@/assets/about/eco-concious/ECOCONS_04_ecofriendlyfood.png"
          />
        </div>
        <div class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2">
          <p class="saoltext text-np-green font-light text-np-2xl">
            eco–friendly food service
          </p>
          <div>
            <p class="robototext neera-text-green content-para">
              we locally source our food to lessen our carbon footprint and are
              very serious about our zero-food waste program. our restaurant’s
              portions are based on sizes and seasonal produce to match each
              person’s needs. for takeaway or food delivery, we encourage the
              practice of reusable items and single-use green utensils and
              packaging. our food never gets unused or leftover, as our team
              will provide a local food bank for the less fortunate and will
              also be donated to local schools.
            </p>
          </div>
        </div>
      </neera-grid-row>
      <neera-grid-row data-aos="fade-up">
        <div>
          <img
            class="img-para w-full"
            src="@/assets/about/eco-concious/ECOCONS_05_wastereduction.png"
          />
        </div>
        <div class="text-left lg:pl-8 py-2 space-y-4 max-w-screen-lg/2">
          <p class="saoltext text-np-green font-light text-np-2xl">
            eco–conscious reducing waste
          </p>
          <div class="robototext neera-text-green content-para space-y-4">
            <p>
              in our common areas, we provide waste and sorting recycling bins.
            </p>
            <p>
              <span class="whitespace-nowrap"
                >at <span class="saoltext">‘neera’</span></span
              >
              we take recycling and upcycling to the next level. we have
              partnered with YOLO and GEPP to manage waste sorting to prevent
              recyclable to landfill and to recycle or upcycle our plastic
              waste.
            </p>
          </div>
        </div>
      </neera-grid-row>
    </neera-grid>
  </div>
</template>

<script>
import HeroWithImage from "../../components/HeroWithImage.vue";
import QuoteBreak from "../../components/QuoteBreak.vue";
import PhotoHorizon from "../../components/PhotoHorizon.vue";
import NeeraGrid from "../../components/layout/NeeraGrid.vue";
import NeeraGridRow from "../../components/layout/NeeraGridRow.vue";
import AOS from "aos";

export default {
  name: "EcoConscious",
  components: {
    HeroWithImage,
    QuoteBreak,
    PhotoHorizon,
    NeeraGrid,
    NeeraGridRow,
  },
  created() {
    const title = "“be eco-conscious”";
    const description =
      "the mellow ambiance, combined with mindful adherence to green practices, even in minor details, leaves visitors with simple ideas they can develop in and around their own homes or workplaces to help the environment. upon arrival, guests will be greeted with a gradated palette of earth tone vibes that exude a warm, welcoming feeling and a sense of seamless unity. recycling bins are placed throughout the premises and all packages are eco-friendly or made of recyclable materials. in the kitchen, locally sourced ingredients are used to ensure freshness, save on transportation energy and promote the community’s produce. takeaway packaging, and utensils are all made from biodegradable materials, so there’s no unnecessary waste. wastewater from the hotel is treated properly before disposal to minimize the environmental impact.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  data() {
    return {
      images: [
        {
          image_url: require("@/assets/about/eco-concious/ECOCONS_02_hotelservice_01.png"),
        },
        {
          image_url: require("@/assets/about/eco-concious/ECOCONS_02_hotelservice_02.png"),
        },
        {
          image_url: require("@/assets/about/eco-concious/ECOCONS_02_hotelservice_03.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.about-para2 {
  color: #f0efea;
  background: #994e2e;
  border-radius: 0px 156px;
}

button {
  background: #994e2e;
  border-radius: 10px;
  color: white;
}

.title-map {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: italic;
  font-weight: 300;
}

.subtitle2 {
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.content-para {
  font-style: normal;
  font-weight: 300;
}

.facilities {
  background: rgba(231, 229, 220, 1);
}

.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
}

.title sup {
  top: -20px;
}

.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}

.menu-content {
  border-top: 1px solid #cecdc8;
}
</style>
